"use client";
import { FC } from "react";
import Link from "next/link";

import { Brand } from "@/icons";
import Button from "@/components/button";
import { Tooltip } from "@nextui-org/tooltip";
import { usePathname } from "next/navigation";
import { setEvent } from "./tracking";
import { EventsEnum } from "@/utils/types";

const Nav: FC = () => {
  const pathname = usePathname();

  return (
    <header className="sticky top-0 container z-50">
      <nav className="flex flex-wrap items-center justify-between pt-8 pb-6">
        <h1 className="hidden">Navigation</h1>
        <div className="flex gap-4 align-center items-center">
          <Tooltip
            placement="bottom"
            content={pathname == "/" ? "You are home" : "Home"}
            closeDelay={0.1}>
            <Link
              href="/"
              className="text-5xl m-0 p-0 transition-all hover:rotate-[45deg]"
              aria-label="Logo">
              <Brand />
            </Link>
          </Tooltip>
        </div>
        <div className="flex gap-2">
          <Button onClick={(e) => {
            setEvent(
              EventsEnum.BookCall,
              EventsEnum.BookCall,
              EventsEnum.BookCall
            );
          }} href="https://cal.com/zero-x-me/30min" variant="primary" className="backdrop-blur-md py-3 px-4">
            Book a call
          </Button>
          <Button variant={"secondary"} href="/contact">
            Contact
          </Button>
        </div>
      </nav>
    </header>
  );
};

export default Nav;
